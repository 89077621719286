
// mdi-account-question-outline
import Vue from 'vue'
import { getApp } from 'firebase/app'
import { OAuthProvider, getAuth, signInWithRedirect } from 'firebase/auth'
import startsWith from 'lodash/startsWith'
import cloneDeep from 'lodash/cloneDeep'
import { mapGetters, mapState } from 'vuex'
import MiniQrRegister from '@components/Auth/MiniQrRegister.vue'
import { getFunctions, httpsCallable } from 'firebase/functions'
import Logo from '@/components/public/Logo.vue'
import AvatarMenu from '@/components/public/AvatarMenu.vue'

import WalletIcon from '@/components/icons/WalletIcon.vue'

export default Vue.extend({
  name: 'SidebarCiudadano',
  components: {
    AvatarMenu,
    Logo,
    MiniQrRegister,
    WalletIcon,
  },
  props: {
    drawerSidebar: {
      type: Boolean,
      default: true,
      required: true,
    },
    typeProfile: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      walletMenu: false,
      userIsRegistered: false,
      userHavePublicAddress: false,
      isLoading: false,
      loading: false,
      show: false,
      snackbar: {
        show: false,
        color: '',
        mode: '',
        timeout: 6000,
        text: '',
        right: '',
        top: '',
      },
      certificatesMenuCitizen: [
        [
          'Portadocumentos',
          'mdi-text-box-check-outline',
          '/citizen/certificates/',
        ],
        ['Clave Fiscal', 'mdi-account', '/citizen/certificates/clave_fiscal'],
        ['DNI Cultural', 'mdi-account', '/citizen/certificates/cultural-dni'],
      ],
      certificatesMenuOperator: [
        ['Extractos', 'mdi-lock', '/admin/certificates/data-certificates'],
      ],
      isDervinsa: process.env.FB_PROJECT_ID === 'dervinsa-6a138',
      isRnLoteriaInteligente:
        process.env.FB_PROJECT_ID === 'rn-loteria-inteligente',
      isMTY:
        process.env.FB_PROJECT_ID === 'os-sandbox-mty' ||
        process.env.FB_PROJECT_ID === 'os-monterrey',
      isRioNegro: process.env.FB_PROJECT_ID === 'os-arg-provincia-rio',
      region: process.env.REGION || 'us-central1',
    }
  },
  computed: {
    ...mapGetters({
      user: 'users/user',
      brand: 'brand/brand',
      editedTexts: 'landingtext/landingtext',
      activeModules: 'modules/getActiveModules',
      userAuxRole: 'users/getAuxRole',
    }),
    ...mapState({
      modules: (state: any) => state.modules.modules,
    }),
    userVerified(): boolean {
      return this.userIsRegistered && this.userHavePublicAddress
    },
    brandSettings(): any {
      return cloneDeep(this.brand)
    },
    isInRegister(): any {
      return (
        startsWith(this.$route.path, '/auth/completeData') ||
        this.$route.path === '/auth/linkWallet'
      )
    },
    hero_background(): string {
      return this.brandSettings.hero_background_type === 'lineal'
        ? `background-color: ${this.brandSettings.hero_bg_primary}`
        : this.brandSettings.hero_background_type === 'gradient'
        ? `background-image: linear-gradient(to bottom, ${this.brandSettings.hero_bg_primary}, ${this.brandSettings.hero_bg_secondary})`
        : this.brandSettings.hero_background_type === 'image'
        ? `background-image: url(${this.brandSettings.hero_bg_image}); background-size: cover; background-position: center;`
        : 'background-image: linear-gradient(to bottom, #5d306f, #bd0b4d)'
    },
  },
  watch: {
    user(val): any {
      if (val.uid) {
        this.verifyUserRegistration(val.uid)
      }
    },
  },
  mounted() {
    if (this.user?.uid) this.verifyUserRegistration(this.user.uid)
    this.certificatesMenuCitizen = this.certificatesMenuCitizen.filter(
      // eslint-disable-next-line array-callback-return
      (value) => {
        return value[0] !== 'Clave Fiscal'
      }
    )
    if (this.isDervinsa) {
      this.certificatesMenuOperator[0][0] = 'Acid'
    }
  },
  methods: {
    async signInSSO() {
      const provider = new OAuthProvider('oidc.vilo')
      const auth = getAuth(getApp())
      await signInWithRedirect(auth, provider)
    },
    getActiveSubmodules(submodules: any) {
      return submodules?.filter((submodule: any) => submodule.active === true)
    },
    async verifyUserRegistration(uid: string) {
      const functions = getFunctions(getApp(), this.region)
      const userEmailExist = httpsCallable(functions, 'users-userEmailExist')
      if (this.user?.uid) {
        try {
          let resp = {} as any
          await userEmailExist({ uid }).then((result) => {
            resp = result.data
          })
          const email = resp.user.email
          const emailValidation = email !== null && email !== ''
          const providerValidation = resp.user.providerData.length > 0
          this.userIsRegistered = emailValidation && providerValidation
        } catch {
          this.userIsRegistered = false
        }
      }
      if (this.user.public_address) {
        this.userHavePublicAddress = true
      } else {
        this.userHavePublicAddress = false
      }
    },
    copyAdressToClipBoard() {
      navigator.clipboard.writeText(this.user.public_address)
    },
  },
})
