import { render, staticRenderFns } from "./MiniQrRegister.vue?vue&type=template&id=452f39f5&scoped=true&"
import script from "./MiniQrRegister.vue?vue&type=script&lang=ts&"
export * from "./MiniQrRegister.vue?vue&type=script&lang=ts&"
import style0 from "./MiniQrRegister.vue?vue&type=style&index=0&id=452f39f5&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "452f39f5",
  null
  
)

export default component.exports