/* eslint-disable no-console */
import Vue from 'vue'
import axios from 'axios'
import firebase from 'firebase/compat/app'
import { addMinutes, format } from 'date-fns'
import Keycloak from '@/static/js/keycloak'
import { signInCustomToken } from '@/services/Firebase'

import { setUserDoc } from '@/services/User'

Vue.use(Keycloak)

export default (context, inject) => {
  let keycloak = null

  const initKeycloak = (reino) => {
    let config = {}
    if (reino === 'afip') {
      config = {
        realm: context.env.AUTENTICAR_AFIP_REALM,
        url: context.env.AUTENTICAR_AFIP_URL,
        'auth-server-url': context.env.AUTENTICAR_AFIP_URL,
        clientId: context.env.AUTENTICAR_AFIP_CLIENT_ID,
        'ssl-required': 'external',
        credentials: {
          secret: context.env.AUTENTICAR_AFIP_CREDENTIAL,
        },
      }
    } else if (reino === 'anses') {
      config = {
        realm: context.env.AUTENTICAR_ANSES_REALM,
        url: context.env.AUTENTICAR_ANSES_URL,
        'auth-server-url': context.env.AUTENTICAR_ANSES_URL,
        clientId: context.env.AUTENTICAR_ANSES_CLIENT_ID,
        'ssl-required': 'external',
        credentials: {
          secret: context.env.AUTENTICAR_ANSES_CREDENTIAL,
        },
      }
    } else if (reino === 'mi-argentina') {
      config = {
        realm: context.env.AUTENTICAR_MI_ARGENTINA_REALM,
        url: context.env.AUTENTICAR_MI_ARGENTINA_URL,
        'auth-server-url': context.env.AUTENTICAR_MI_ARGENTINA_URL,
        clientId: context.env.AUTENTICAR_MI_ARGENTINA_CLIENT_ID,
        'ssl-required': 'external',
        credentials: {
          secret: context.env.AUTENTICAR_MI_ARGENTINA_CREDENTIAL,
        },
      }
    } else if (reino === 'renaper') {
      config = {
        realm: context.env.AUTENTICAR_RENAPER_REALM,
        url: context.env.AUTENTICAR_RENAPER_URL,
        'auth-server-url': context.env.AUTENTICAR_RENAPER_URL,
        clientId: context.env.AUTENTICAR_RENAPER_CLIENT_ID,
        'ssl-required': 'external',
        credentials: {
          secret: context.env.AUTENTICAR_RENAPER_CREDENTIAL,
        },
      }
    }

    keycloak = Keycloak(config)
  }

  const getUser = async (uid, method) => {
    try {
      return await axios
        .post(`${context.env.OSCITY_ENDPOINTS_URL}/${method}`, {
          uid,
        })
        .then((userExist) => {
          return Object.entries(userExist.data.user).length > 0
            ? userExist.data.user
            : null
        })
        .catch((error) => {
          console.error(error)
          context.redirect('/autenticar-error')
        })
    } catch (error) {
      console.error(error)
      context.redirect('/autenticar-error')
    }
  }

  const validateRegister = async (uid) => {
    try {
      return await axios
        .post(
          `${context.env.OSCITY_ENDPOINTS_URL}/validUsers-validateRegister`,
          {
            uid,
          }
        )
        .then((userExist) => {
          if (typeof userExist.data === 'object') {
            return Object.entries(userExist.data.user).length > 0
              ? userExist.data.user
              : null
          } else {
            return userExist
          }
        })
        .catch((error) => {
          console.log(error)
          context.redirect('/autenticar-error')
        })
    } catch (error) {
      console.error(error)
      context.redirect('/autenticar-error')
    }
  }

  // reino => Hacer logout y guardar por que reino se registro o subió de nivel
  const reino = localStorage.getItem('reino')
  // backToReino => Saber si al llegar al front viene de un reino o no. Puede ser borrada del storage al momento de volver
  const backToReino = localStorage.getItem('backToReino')

  if (reino) {
    const isRioNegro = context.env.FB_PROJECT_ID === 'os-arg-provincia-rio'
    if (backToReino) {
      initKeycloak(reino)
      keycloak
        .init({
          checkLoginIframe: false,
          onLoad: 'check-sso',
        })
        .success((auth) => {
          if (auth) {
            keycloak
              .loadUserInfo()
              .success(async (res) => {
                const cuit = res.cuit ? res.cuit : res.preferred_username
                const autenticarUser = {
                  ...res,
                  cuit,
                }
                let user = {}
                const sso = context.store.getters['authconfig/getsso']
                if (sso) {
                  user = await getUser(cuit, 'users-getUserByCuit')
                } else {
                  user = await validateRegister(cuit)
                }
                console.log(user)
                console.log(user.data === 'noAction')
                if (!user || user.data === 'noAction') {
                  console.log('isRioNegro keycloack', isRioNegro)
                  console.log('res', res)
                  // Si no es río negro se sigue el flujo
                  // Si es rio negro, se valida primero el nivel en afip (3 o 4) o en mi argentina (3)
                  if (
                    (res &&
                      isRioNegro &&
                      ((reino === 'afip' && ['3', '4'].includes(res.nivel)) ||
                        (reino === 'mi-argentina' && res.nivel === '3') ||
                        reino === 'renaper')) ||
                    !isRioNegro
                  ) {
                    console.log('No existe el usuario')
                    // Si no existe el usuario se guarda en el store para que pueda terminar su registro
                    const autenticarData = {
                      first_name: res.given_name ? res.given_name : '',
                      autenticated: reino,
                      last_name: res.family_name ? res.family_name : '',
                      activated: false,
                      dni: autenticarUser.cuit,
                      levelCidi: isRioNegro ? res.nivel : 1,
                      alreadyRegister: false,
                      levelUpdated: false,
                      tipoPersona: ['F', 'FISICA'].includes(res.tipo_persona)
                        ? 'f'
                        : 'j',
                      created: firebase.firestore.Timestamp.now(),
                      autenticarRes: { ...res },
                      hasLevelRequired: reino !== 'renaper',
                    }
                    console.log('Actualizaré store')
                    context.store.dispatch('setUserAutenticar', {
                      autenticar: {
                        ...autenticarData,
                        uid: autenticarUser.cuit,
                      },
                    })
                  } else {
                    console.log('no tiene el nivel necesario')
                    const autenticarData = {
                      hasLevelRequired: false,
                    }
                    context.store.dispatch('setUserAutenticar', {
                      autenticar: {
                        ...autenticarData,
                      },
                    })
                  }
                } else if (
                  user &&
                  user.data !== 'noAction' &&
                  reino === 'afip' &&
                  !isRioNegro
                ) {
                  console.log('Vengo de afip')
                  if (res && ['3', '4'].includes(res.nivel)) {
                    console.log('Es nivel 3 o 4')
                    const uidUser = sso ? user.uid : autenticarUser.cuit
                    axios
                      .post(
                        `${context.env.OSCITY_ENDPOINTS_URL}/usersAuth-createCustomToken`,
                        {
                          uid: uidUser,
                        }
                      )
                      .then((customToken) => {
                        signInCustomToken(context.$fire, {
                          credential: customToken.data.customToken,
                        }).then(() => {
                          try {
                            console.log('Actualizar store')
                            const autenticarData = {
                              levelCidi: 2,
                              levelUpdated: true,
                              autenticated: reino,
                              tipoPersona: ['F', 'FISICA'].includes(
                                res.tipo_persona
                              )
                                ? 'f'
                                : 'j',
                              updatedLevel: firebase.firestore.Timestamp.now(),
                            }
                            const data = {
                              ...autenticarData,
                              role: user.customClaims
                                ? user.customClaims.role
                                : user.role,
                              availableModules: user.customClaims
                                ? user.customClaims.availableModules
                                : user.availableModules,
                            }
                            const userData = Object.assign({}, data)
                            setUserDoc(context.$fire, uidUser, userData)
                            context.store.dispatch('setUserAutenticar', {
                              autenticar: {
                                ...autenticarData,
                                uidUser,
                              },
                            })
                            context.store.dispatch('users/setUserData', {
                              data: {
                                ...userData,
                              },
                            })
                          } catch (error) {
                            console.error(error)
                            context.store.commit('users/SET_USER', {
                              levelUpdated: false,
                            })
                          }
                        })
                      })
                  } else {
                    console.log('No es nivel 3 o 4')
                    context.store.commit('users/SET_USER', {
                      levelUpdated: false,
                    })
                  }
                } else if (
                  user &&
                  user.data !== 'noAction' &&
                  (reino !== 'afip' || (reino === 'afip' && isRioNegro))
                ) {
                  console.log('Ya tengo un registro')
                  if (user.email) {
                    console.log('Completé el registro')
                    // Si ya existe el usuario mandar a que realice el login
                    const cuit = res.cuit ? res.cuit : res.preferred_username
                    localStorage.setItem('cuit', cuit)
                    const autenticarData = {
                      alreadyRegister: true,
                    }
                    context.store.dispatch('setUserAutenticar', {
                      autenticar: {
                        ...autenticarData,
                      },
                    })
                    if (!context.store.getters['authconfig/getsso']) {
                      setTimeout(() => {
                        context.redirect('/auth/login')
                      }, 700)
                    }
                  } else {
                    console.log('No completé el registro, no hay email')
                    const autenticarData = {
                      first_name: res.given_name ? res.given_name : '',
                      autenticated: reino,
                      last_name: res.family_name ? res.family_name : '',
                      activated: false,
                      dni: autenticarUser.cuit,
                      levelCidi: 1,
                      alreadyRegister: false,
                      levelUpdated: false,
                      tipoPersona: ['F', 'FISICA'].includes(res.tipo_persona)
                        ? 'f'
                        : 'j',
                      created: firebase.firestore.Timestamp.now(),
                      autenticarRes: { ...res },
                    }
                    context.store.dispatch('setUserAutenticar', {
                      autenticar: {
                        ...autenticarData,
                        uid: autenticarUser.cuit,
                      },
                    })
                  }
                }
              })
              .error(() => {
                context.redirect('/autenticar-error')
              })
          } else {
            // Sino existen datos del inicio de keycloak
            context.redirect('/autenticar-error')
          }
        })
        .error(() => {
          // Si hubo un error al inicial keycloak
          context.redirect('/autenticar-error')
        })
    } else if (!context.store.getters.loggedIn) {
      // Si no viene de autenticar y no existe una sesión, se hace logout de autenticar ya que si existe la variable 'reino'
      // Para no dejar sesión iniciada en autenticar
      initKeycloak(reino)
      keycloak.init({
        checkLoginIframe: false,
        onLoad: 'check-sso',
      })
      keycloak.logout()
      localStorage.removeItem('reino')
    }
  }

  inject('autenticar', {
    init: (reino) => {
      const currentDate = format(
        addMinutes(new Date(), 3),
        "yyyy-MM-dd'T'HH:mm:ss"
      )
      console.log('currentDate', currentDate)
      localStorage.setItem('reino', reino)
      localStorage.setItem('backToReino', currentDate)
      initKeycloak(reino)
      keycloak
        .init({
          onLoad: 'login-required',
          checkLoginIframe: false,
        })
        .success(() => {})
        .error(() => {
          context.redirect('/autenticar-error')
        })
    },
    logout: (reino) => {
      try {
        keycloak.logout()
      } catch {
        if (!reino) {
          reino = localStorage.getItem('reino')
        }
        initKeycloak(reino)
        keycloak.init({
          checkLoginIframe: false,
          onLoad: 'check-sso',
        })
        keycloak.logout()
      }
    },
  })
}
